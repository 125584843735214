<template>
  <app-icon-btn
    :icon-height="iconSize"
    class="collection-action-button"
    icon-name="mdi-cancel"
    wrapper-class="d-flex"
    @click="$emit('manageActions', 'ignoreSuggestion')">
    <p
      class="small-p publish">
      Ignore
    </p>
  </app-icon-btn>
</template>
<script>
export default {
  name: 'SuggestionIgnore',
  props: {
    iconSize: {
      type: Number,
      default: 16,
    },
  },
};
</script>
<style scoped lang="scss">
.v-icon {
  cursor: pointer;
}
.small-p {
  padding: 0 !important;
}
</style>
